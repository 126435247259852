import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import ClientStripe from '../assets/images/circle-stripe.png';
import aboutImage1 from '../assets/images/about-3.jpg';
import aboutImage2 from '../assets/images/about-4.jpg';

class AboutOne extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className='about-one '>
        <img src={ClientStripe} className='about-one__circle' alt='' />
        <h2
          className='block-title__title'
          style={{ marginBottom: 20, textAlign: 'center' }}
        >
          Nigeria's Foremost Teaching Hospital
        </h2>
        <div className='container text-center'>
          {/* <div className='block-title text-center'>
            <h2 className='block-title__title'>
              Lagos University Teaching Hospital (LUTH)
            </h2>
          </div> */}
          <div className='about-one__img'>
            <div className='row'>
              <div className='col-lg-6'>
                <img src={aboutImage1} alt='' />
              </div>
              <div className='col-lg-6'>
                <img src={aboutImage2} alt='' />
              </div>
            </div>
            {/* <div className="about-one__review">
              <p className="about-one__review-count counter">
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp end={this.state.startCounter ? 88750 : 0} />
                </VisibilitySensor>
              </p>
              <div className="about-one__review-stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </div>
              <p className="about-one__review-text">Nigeria's Foremost Teaching Hospital</p>
            </div> */}
          </div>
          <h2 className='block-title__title'>LUTH’S Vision</h2>
          <p className='about-one__text'>
            A foremost tertiary Hospital with a mandate to provide excellent
            services of international standard in{' '}
            <strong>patient care, training & research</strong>
          </p>
          <h2 style={{ marginTop: 30 }} className='block-title__title'>
            LUTH’S Mission
          </h2>
          <p className='about-one__text'>
            Develop professional skilled and motivated staff to provide
            excellent services, training and research using appropriate
            technology through partnership with private sector and other
            stakeholders, in an environmentally friendly manner.
          </p>
          <h2 className='block-title__title'>Goal</h2>
          <p className='about-one__text' style={{ marginTop: 20 }}>
            Our Corporate goal as a foremost tertiary institution of
            international standard is to provide patient care, produce medical
            and health manpower, and investigate health related problems by
            applying the highest standard of promptness, competitiveness and
            effectiveness. This will be achieved through appropriate
            communication between staff and patients, their relations among
            staff, between LUTH and the public and between LUTH and its
            professional affiliates, in furtherance of this goal, use will be
            made of well motivated Staff as well as expertise based on the
            highest skill and experience in an environment that is clean,
            conducive and patient-friendly.
          </p>
        </div>
      </section>
    );
  }
}

export default AboutOne;
